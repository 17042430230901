import React from 'react'

import SiteHeadMetadata from 'components/SiteHeadMetadata'

const NotFoundPage = () => (
  <>
    <SiteHeadMetadata title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>The requested resource was not found.</p>
  </>
)

export default NotFoundPage
